<template>
    <div class="app-dialog" v-if="value"
        :class="{
            [child_class]: child_class,
            [classScreenWithIOs]: classScreenWithIOs,
        }"
    >
        <div class="app-dialog-container" :style="styleMaxSize">
            <div class="app-dialog-body" v-if="initialized">
                <slot name="loader">
                    <app-loader v-if="is_loading"></app-loader>
                </slot>
                
                <slot name="head"></slot>

                <slot name="img"></slot>

                <slot name="body">
                    <slot name="error">
                        <app-error v-model="errors.show" :message="errors.message"></app-error>
                    </slot>

                    <form @keyup.enter="confirm" @submit.prevent.stop>
                        <slot name="form"></slot>
                    </form>

                    <div class="btns">
                        <slot name="btns">
                            <button class="btn btn-white" @click="close" :disabled="is_loading" v-if="false">Cancel</button>
                        </slot>
                    </div>
                </slot>
            </div>

            <button class="btn btn-close app-dialog-close" @click="close(true)" :disabled="is_loading" v-if="closable"></button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import * as bodyScrollLock from 'body-scroll-lock'

import appError from '@/components/app-error'
import appLoader from '@/components/app-loader'

const views = {
    full: true,
    bottom: true,
}

let scrollPosition = 0

export default {
    props: {
        value:         { required: true                    },
        closable:      { type: Boolean, default: true      },

        loading:       { type: Boolean, default: false     },
        processing:    { type: Boolean, default: false     },

        errors:        {
            type: Object,
            default: () => ({
                show: false,
                message: '',
            })
        },

        maxWidth:     { type: [Number, String], default: null },    // $dialog-max-width: 640px;
        maxHeight:    { type: [Number, String], default: null },    // $dialog-max-height: 480px;

        blur:         {
            type: Object,
            default: () => ({
                show: false,
                only: null,     // null | `mobile` | `tablet` | `desktop`
                except: null,   // null | `mobile` | `tablet` | `desktop`
            })
        },
        tabletView:  { type: String, default: null }, // null | `full` | `bottom`
        mobileView:  { type: String, default: null }, // null | `full` | `bottom`

        withScrollLock: { type: Boolean, default: true },

        parent: { default: null },
    },

    components: {
        appError,
        appLoader,
    },

    data() {
        return {
            self: false,
            is_shown: false,

            child_class: null,

            dialog_body_padding: 0,

            initialized: false,
        }
    },

    created() {
        if (this.value) {
            this.open()
        }

        this.reset()
    },

    mounted() {
        this.handleWindowResize()
        window.addEventListener('resize', this.handleWindowResize)

        this.initBlur()
    },
    
    methods: {
        beforeOpen() {
            return this
        },

        /**
         * @todo Сделать сохранение текущей позиции скрола при открытии диалога и возвращение к ней после закрытия
         */
        open() {
            this.beforeOpen()

            // document.documentElement.classList.add('shown-dialog')
            this.initBlur()

            this.$nextTick(() => {
                this.showDialog()
            })

            return this
        },

        confirm() {
            this.$emit('confirm')
        },

        close(is_closes_with_button) {
            this.self = true
            this.$emit('input', false)
            this.$emit('closes', is_closes_with_button)
            
            // this.$nextTick(() => {
                // document.documentElement.classList.remove('shown-dialog')
                this.hideDialog()
            // })

            this.$nextTick(() => {
                this.removeBlur()
            })

            return this
        },

        routerPush(route) {
            this.close()
            
            this.$nextTick(()=>{
                this.$router.push(route)
            })
        },

        reset() {
            return this
        },

        showDialog() {
            if (!this.is_shown) {
                if (this.withScrollLock) {
                    bodyScrollLock.disableBodyScroll(this.$el)
                }

                this.is_shown = true

                if (this.ios) {
                    scrollPosition = window.pageYOffset

                    document.body.style.overflow = 'hidden'
                    document.body.style.position = 'fixed'
                    document.body.style.top = `-${scrollPosition}px`
                    document.body.style.width = '100%'
                }

                document.documentElement.classList.add('dialog-opened')
            }
        },
        hideDialog() {
            if (this.is_shown) {
                if (this.withScrollLock) {
                    bodyScrollLock.enableBodyScroll(this.$el)
                }

                this.is_shown = false

                if (this.ios) {
                    document.body.style.removeProperty('overflow')
                    document.body.style.removeProperty('position')
                    document.body.style.removeProperty('top')
                    document.body.style.removeProperty('width')

                    window.scrollTo(0, scrollPosition)
                }

                document.documentElement.classList.remove('dialog-opened')
            }
        },

        initBlur() {
            this.removeBlur()

            if (this.blur.show) {
                if (this.$el) {
                    document.getElementById('app').append(this.$el)

                    this.$nextTick(() => {
                        this.initialized = true
                    })
                }

                switch (this.blur.only) {
                    case 'mobile':  { document.documentElement.classList.add('dialog-blur-mobile') } break
                    case 'tablet':  { document.documentElement.classList.add('dialog-blur-tablet') } break
                    case 'desktop': { document.documentElement.classList.add('dialog-blur-desktop') } break
                    default:        { document.documentElement.classList.add('dialog-blur') }
                }

                switch (this.blur.except) {
                    case 'mobile':  { document.documentElement.classList.add('dialog-blur-except-mobile') } break
                    case 'tablet':  { document.documentElement.classList.add('dialog-blur-except-tablet') } break
                    case 'desktop': { document.documentElement.classList.add('dialog-blur-except-desktop') } break
                }
            } else if (this.parent) {
                if (this.$el) {
                    this.parent.append(this.$el)

                    this.$nextTick(() => {
                        this.initialized = true
                    })
                }
            } else {
                this.initialized = true
            }
        },

        removeBlur() {
            document.documentElement.classList.remove('dialog-blur')
            document.documentElement.classList.remove('dialog-blur-mobile')
            document.documentElement.classList.remove('dialog-blur-tablet')
            document.documentElement.classList.remove('dialog-blur-desktop')
            document.documentElement.classList.remove('dialog-blur-except-mobile')
            document.documentElement.classList.remove('dialog-blur-except-tablet')
            document.documentElement.classList.remove('dialog-blur-except-desktop')
        },

        handleWindowResize() {
            const clientWidth = document.body.clientWidth

            this.dialog_body_padding =
                clientWidth <= this.$mobile_size
                ? this.$container_padding_mobile
                : (
                    clientWidth <= this.$tablet_size
                        ? this.$container_padding_tablet
                        : this.$container_padding
                )
        },
    },

    computed: {
        ...mapGetters([
            '$tablet_size',
            '$mobile_size',

            '$container_padding',
            '$container_padding_tablet',
            '$container_padding_mobile',
        ]),

        is_loading() {
            return this.loading || this.processing
        },

        styleMaxSize() {
            // console.group('styleMaxSize()')
            let style = {}
            
            // console.log('this.maxWidth', this.maxWidth)
            // console.log('this.dialog_body_padding', this.dialog_body_padding)
            if (this.maxWidth) {
                // max-width: $dialog-max-width + $dialog-body-padding * 2;
                style.maxWidth = `${+this.maxWidth + this.dialog_body_padding * 2}px`
            }

            if (this.maxHeight) {
                // max-height: $dialog-max-height + $dialog-body-padding * 2;
                style.maxHeight = this.maxHeight == 'none'
                    ? 'none'
                    : `${+this.maxHeight + this.dialog_body_padding * 2}px`
            }
            // console.groupEnd()

            return style
        },

        classTabletScreen() {
            return this.tabletView && views[ this.tabletView ] ? `tablet-view-${ this.tabletView }` : null
        },
        classMobileScreen() {
            return this.mobileView && views[ this.mobileView ] ? `mobile-view-${ this.mobileView }` : null
        },

        classScreenWithIOs() {
            let class_with_ios = this.classTabletScreen || this.classMobileScreen

            if (this.ios) {
                const ios_class = 'ios-device'

                if (class_with_ios) {
                    class_with_ios+= ' ' + ios_class
                } else {
                    class_with_ios = ios_class
                }
            }
            
            return class_with_ios
        },

        ios() {
            return typeof window !== 'undefined' && window.navigator && window.navigator.platform && /iP(ad|hone|od)/.test(window.navigator.platform)
        },
    },

    watch: {
        value() {
            if (this.self) {
                this.self = false
            } else {
                if (this.value) {
                    this.open()
                } else {
                    this.close()
                }
            }
        },

        startStep() {
            this.goStep(this.startStep)
        },

        blur: {
            handler() {
                this.initBlur()
            },
            deep: true,
        },
    },

    beforeDestroy() {
        // document.documentElement.classList.remove('shown-dialog')
        this.hideDialog()
        this.removeBlur()
        window.removeEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss">
$dialog-max-width: 640px;
$dialog-max-height: 480px;
$dialog-min-height: 320px;

$dialog-body-padding: $container-padding;

$dialog-body-background: var(--color-component-bg-primary);

.app-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    display: flex;
    height: auto;
    background-color: var(--color-dialog-over-primary);
    z-index: $z-index-dialog;

    .app-dialog-container {
        width: 100%;
        height: auto;
        max-width: $dialog-max-width + $container-padding * 2;
        // max-height: $dialog-max-height + $container-padding * 2;
        min-height: $dialog-min-height;
        margin: auto;
        padding: $container-padding;
        position: relative;

        .app-dialog-close {
            position: absolute;
            top: 16px + $container-padding;
            right: 16px + $container-padding;
        }

        .app-dialog-body {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: $dialog-body-background;
            padding: 80px;
            position: relative;
            border-radius: $border-radius-secondary;
            box-shadow: var(--box-shadow-primary);

            .app-loader {
                &::after {
                    width: 48px;
                    height: 48px;
                }
            }

            .app-error {
                &:not(:last-child) {
                    margin-bottom: 24px;
                }
            }

            h2 {
                margin-bottom: 40px;
                text-align: center;
            }

            h3 {
                font-size: 32px;
                line-height: 40px;
                font-weight: bold;
                flex-shrink: 0;
                margin-bottom: 50px;
                text-align: center;
            }

            h4 {
                font-size: 28px;
                line-height: 1.2;
                margin: 20px 0;
                text-align: center;
            }

            img {
                // margin-bottom: 40px;
            }

            form {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                &>:not(:last-child):not(label) {
                    margin-bottom: 30px;
                }

                &>label:not(.app-checkbox) {
                    text-align: left;
                    margin-bottom: 10px;
                    font-size: 18px;
                    max-width: 250px;
                }
            }

            p {
                width: 100%;
                font-size: 18px;
                line-height: 24px;
                font-weight: 300;
                text-align: center;
                margin-bottom: 20px;
                word-break: break-word;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .btns {
                display: flex;
                justify-content: space-around;
                width: 100%;
                margin-top: 24px;

                &:empty {
                    display: none;
                }

                .btn {
                    flex-grow: 1;
                    flex-shrink: 1;
                    margin: 0 3%;

                    &:first-child { margin-left: 0; }
                    &:last-child { margin-right: 0; }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .app-dialog {
        .app-dialog-container {
            max-width: $dialog-max-width + $container-padding-tablet * 2;
            max-height: $dialog-max-height + $container-padding-tablet * 2;
            padding: $container-padding-tablet;

            .app-dialog-close {
                top: 24px + $container-padding-tablet;
                right: 24px + $container-padding-tablet;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog {
        .app-dialog-container {
            max-width: $dialog-max-width + $container-padding-mobile * 2;
            max-height: none;
            padding: $container-padding-mobile;

            .app-dialog-close {
                top: 16px + $container-padding-mobile;
                right: 16px + $container-padding-mobile;

                &:after {
                    width: 16px;
                    height: 16px;
                }
            }

            .app-dialog-body {
                height: auto;
                padding: 64px 24px;

                // h2 {
                //     margin-bottom: 16px;
                // }

                h3 {
                    font-size: 24px;
                    line-height: 32px;
                    margin-bottom: 40px;
                }

                form {
                    &:not(:last-child) {
                        margin-bottom: 16px;
                    }

                    &>:not(:last-child):not(label) {
                        margin-bottom: 24px;
                    }

                    button {
                        height: 50px;
                        font-size: 20px;

                        &:not(:last-child) {
                            margin-bottom: 40px;
                        }
                    }
                }

                p {
                    font-size: 16px;
                }

                .btns {
                    flex-direction: column;
                    margin-top: 16px;
                    padding: 0 16px;

                    .btn {
                        margin: 0 0 16px;
                        width: 100%;
                        max-width: none;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

@mixin dialog-view-full($header-height) {
    top: $header-height;
    display: block;
    background: $dialog-body-background;
    z-index: $z-index-sub-header;

    .app-dialog-container {
        height: 100%;
        max-width: 100% !important;
        max-height: none !important;
        padding: 0;

        .app-dialog-body {
            min-height: 100%;
            border-radius: 0;

            .btns {
                flex-direction: column;
            }
        }

        .app-loader {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

@mixin dialog-view-bottom() {
    align-items: flex-end;

    .app-dialog-container {
        margin: 0;
        padding: 16px 0 0;
        max-height: 100%;
        max-width: 100% !important;
        display: flex;

        .app-dialog-body {
            justify-content: flex-start;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

        }
    }
}

@media (max-width: $tablet-size) {
    .app-dialog {
        &.tablet-view-full {
            @include dialog-view-full($header-height-tablet);
        }

        &.tablet-view-bottom {
            @include dialog-view-bottom();
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog {
        &.mobile-view-full {
            @include dialog-view-full($header-height-mobile);
        }

        &.mobile-view-bottom {
            @include dialog-view-bottom();
        }
    }
}
</style>
